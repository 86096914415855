import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
// import Swiper and modules styles
Swiper.use([Navigation, Pagination, Autoplay]);
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

export default class Slider {
  constructor() {  
    this.$section = $('section.slider');  
  }
}


document.addEventListener("DOMContentLoaded", () => {
  new Swiper('.swiper', {
    loop: true,
    autoplay: true,
    modules: [Navigation, Pagination, Autoplay],
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },
    
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
})